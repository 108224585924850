import React from "react";
import { mapEdgesToNodes } from "../lib/helpers";
import { StaticQuery, graphql } from "gatsby";
import FooterList from "./footer-list.js";

import styles from "./footer.module.css";

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            companyEmail
          }
          offices: allSanityOffice {
            edges {
              node {
                id
                title
                visitorAddress
                visitorPostNumber
                phoneNumber
              }
            }
          }
        }
      `}
      render={(data) => (
        <footer className={styles.footer}>
          <div className={styles.footerWrapper}>
              <FooterList
                companyTitle={data.site.title}
                companyEmail={data.site.companyEmail}
                nodes={mapEdgesToNodes(data.offices)}
              />
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
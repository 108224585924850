import { Link, StaticQuery, graphql } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => {
  return (
    <StaticQuery
      query={graphql`
        fragment SanityImage on SanityMainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        query HeaderQuery {
          site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            logoImage {
              ...SanityImage
              alt
            }
          }
        }
      `}
      render={(data) => (
        <div className={styles.root}>
          <div className={styles.wrapper}>
            <div className={styles.mobileWrapper}>
              <Link to="/">
                {data.site.logoImage && data.site.logoImage.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(data.site.logoImage))
                      .width(60)
                      .height(50)
                      .auto("format")
                      .url()}
                    alt={data.site.logoImage.alt}
                  />
                  
                )}
              </Link>
              <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
                <Icon symbol="hamburger" />
              </button>
            </div>

            <nav className={cn(styles.nav, showNav && styles.showNav)}>
              <ul>
                <li>
                  <Link to="/artikler/">ARTIKLER</Link>
                </li>
                <li>
                  <Link to="/tjenester/">TJENESTER</Link>
                </li>
                <li className={showNav ? styles.hideLogo : ""}>
                  <Link to="/">
                  {data.site.logoImage && data.site.logoImage.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(data.site.logoImage))
                      .width(100)
                      .auto("format")
                      .url()}
                    alt={data.site.logoImage.alt}
                  />
                )}
                  </Link>
                </li>
                <li>
                  <Link to="/om-pso/">OM PSO</Link>
                </li>
                <li>
                  <Link to="/kontakt/">KONTAKT</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    />
  );
};

export default Header;

import React from "react";
import { FaPhoneAlt, FaEnvelope, FaHome } from "react-icons/fa";
import { formatPhoneNumber } from "../lib/helpers.js";

import styles from "./footer-list.module.css";

function FooterList(props) {
  return (
    <>
      {props.nodes &&
        props.nodes.map((node) => (
          <ul className={styles.list}>
            <li>
              <h3>
                {props.companyTitle} {node.title}
              </h3>
            </li>
            <li className={styles.listItem}>
              <i>
                <FaHome />
              </i>
              <p>
                {node.visitorAddress} <br />
                {node.visitorPostNumber} {node.title}
              </p>
            </li>
            <li className={styles.listItem}>
              <i>
                <FaPhoneAlt />
              </i>
              <p>{formatPhoneNumber(node.phoneNumber)}</p>
            </li>
            <li className={styles.listItem}>
              <i>
                <FaEnvelope />
              </i>
              <p>
                <a href={`mailto:${props.companyEmail}`}>{props.companyEmail}</a>
              </p>
            </li>
          </ul>
        ))}
    </>
  );
}

export default FooterList;
